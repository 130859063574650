import moment from 'moment'
import { groupBy as groupByLoadash } from 'lodash'

export const Prev_URL = {pathname: ''}

export const clinicalDashboardRoutes = [
  'Emergency',
  'ClinicalDashboard/Overview',
  'ClinicalDashboard/Problems',
  'ClinicalDashboard/Allerges',
  'ClinicalDashboard/Visits',
  'ClinicalDashboard/LabOrders',
  'ClinicalDashboard/PathelogyOrders',
  'ClinicalDashboard/RadioOrders',
  'ClinicalDashboard/Surgeries',
  'ClinicalDashboard/Interventions',
  'ClinicalDashboard/Prescriptions',
  'ClinicalDashboard/Medications',
  'ClinicalDashboard/Doctor_Medications',
  'ClinicalDashboard/FamilyHistory',
  'ClinicalDashboard/Chronic',
  'ClinicalDashboard/Vaccination',
  'ClinicalDashboard/Vitals',
  'ClinicalDashboard/Chronic',
  'ClinicalDashboard/Ongoing',
  'ClinicalDashboard/Resolved'

]

const doctorRoutes = [
  'home',
  'appointment',
  ...clinicalDashboardRoutes,
  'ClinicalDashboard/Private_Data',
  'erx', 'emr', 'createPatient',
  'showCode',
  'radio', 'lab', 'pathelogy', 'visit',
  'patient-register', 'account-settings', 'QrCodeChecker',
  'fastVisit',
  'checkPatient',
  'Medical_Network'
]
const doctorFdRoutes = ['home', 'appointment', 'account-settings', 'QrCodeChecker']
const nurseRoutes = [
  'home', 'nurse', 'add_vitals',
  ...clinicalDashboardRoutes,
  'account-settings', 'QrCodeChecker'
]
const radioFdRoutes = [
  'home',
  'radioFD',
  'radioFD/Results',
  'radioFD/Accepted',
  'radioFD/Not_Accepted',
  'account-settings',
  'QrCodeChecker'
]
const pathoFdRoutes = [
  'home',
  'pathoFD',
  'pathoFD/Results',
  'pathoFD/Accepted',
  'pathoFD/Not_Accepted',
  'account-settings',
  'QrCodeChecker'
]
const labFdRoutes = [
  'home',
  'labFD',
  'labFD/Results',
  'labFD/Accepted',
  'labFD/Not_Accepted',
  'account-settings',
  'QrCodeChecker'
]
const pharmcistRoutes = [
  'home',
  'pharmcist',
  'pharmcist/records',
  'pharmcist/patient',
  'pharmcist/patient/current',
  'pharmcist/patient/related',
  'pharmcist/patient/all',
  'account-settings',
  'QrCodeChecker'
]
const subAdminRoutes = [
  'home',
  'patients',
  'patients/medcloud',
  'patients/generated',
  'patients/registered',
  'patients/trustLab',
  'patients/trinity',
  'patients/espitalia',
  'patients/trustLab/generated',
  'patients/trustLab/registered',
  'patients/online',
  'account-settings',
  'QrCodeChecker',
  ...clinicalDashboardRoutes

]
const doctorAdminRoutes = [
  'home', 'doctors', 'create_doctor', 'account-settings', 'QrCodeChecker',
  ...clinicalDashboardRoutes

]
const mainAdminRoutes = [
  'home', 'main/doctors',
  'main/radios', 'main/labs',
  'main/pathologys', 'main/pharmacist', 'main/physiotherapist', 'main/Dentist',
  'create_doctor', 'account-settings',
  'other/create',
  'main/clinicOwners',
  'main/doctorClinicOwners',
  'updateDoctor',
  'updateRadioFD',
  'updateLabFD',
  'updatePharmacist',
  'create_employee_price',
  'updatePhysiotherapist',
  'updateDentist',
  'create_price',
  'create_areaAdmin',
  'main/AreaAdmins'
]

const areaAdminRoutes = [
  'home', 'main/doctors',
  'main/radios', 'main/labs',
  'main/pathologys', 'main/pharmacist',
  'account-settings',
  'main/clinicOwners',
  'main/doctorClinicOwners',
  'updateDoctor',
  'updateRadioFD',
  'updateLabFD',
  'updatePharmacist',
  'create_employee_price',
  'updatePhysiotherapist',
  'updateDentist', 'main/physiotherapist', 'main/Dentist'
]

const medicalRegisteralRoutes = [
  'home',
  'update-patient-register',
  'registeral/patients',
  'registeral/registerations',
  'patient-register',
  'account-settings',
  'lab',
  'radio',
  'pathelogy',
  ...clinicalDashboardRoutes
]

const PHCURoutes = [
  'home',
  'registeral/patients',
  'Registrars',
  'patient-register',
  'account-settings',
  'lab',
  'radio',
  'pathelogy',
  'create_registeral',
  ...clinicalDashboardRoutes
]


const vendorRoutes = [
  'home',
  'vendor/patients',
  'vendor/files',
  'account-settings',
  ...clinicalDashboardRoutes
]


export const LinksWithoutBack = [
  "login", "home",
  "register", "showCode",
  'landing',
  'landing_ar',
  "forget",
  "create_nurse",
  "QrCodeChecker",
  '',
  'change/password',
  'patient_showCode',
  'fastVisit',
  'visit',
  'setup',
  'trustLab/register',
  'trinity/register',
  'espitalia/register',
  'Medical_Network',
  'Medical_Network/Details',
  'qrcodesession',
  'MedicalNetworkFile',
  ...clinicalDashboardRoutes
]

export const LinksWithoutNavBar = ["QrCodeChecker"]

export const SidebarShow = ["ClinicalDashboard"]

export const CachedArabicLinks = [
  'Medical_Network',
  'Medical_Network/Details',
  'trustLab/register',
  'trinity/register',
  'espitalia/register',
  'register',
  'QrCodeChecker',
  'showCode'

]

export const getNoOptionsText = () => {
  return 'Type To Search'
}

export const getNoOptionsSelectText = (RTL) => {
  return !RTL ? 'No options' : 'لا توجد بيانات'
}
export const getOptionSelectText = (RTL) => {
    return !RTL ? 'Select...' : 'اختر...'
}
export const getMyAge = (birthDate) => {
  const date1 = new Date()
  const age = date1.getFullYear() - birthDate?.split('-')[0]

  // if (age <= 3) {
  const date2 = new Date(birthDate)
  const newDate = moment([date1.getFullYear(), date1.getMonth(), date1.getDate()])
  const oldDate = moment([date2.getFullYear(), date2.getMonth(), date2.getDate()])
  const years = newDate.diff(oldDate, 'year')
  oldDate.add(years, 'years')
  const months = newDate.diff(oldDate, 'months')
  oldDate.add(months, 'months')
  const days = newDate.diff(oldDate, 'days')
  oldDate.add(days, 'days')

  let str = ''
  if (years === 0 && months === 0) return `${days} d`
  else if (years === 0 && months > 0) {
    if (months > 0) str += `${months} m `
    if (days > 0) str += `${days} d`
  } else if (years > 0) {
    if (years > 0) str += `${years} y `
    if (months > 0) str += `${months} m`
  }
  return str
  // }
  // return age
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export function PrintElem(elem) {
  const mywindow = window.open('', 'PRINT')

  mywindow.document.write(`<html><head>`)
  mywindow.document.write('</head><body >')
  mywindow.document.write(elem)
  mywindow.document.write('</body></html>')
  setTimeout(function () {
    mywindow.document.close() // necessary for IE >= 10
    mywindow.focus() // necessary for IE >= 10*/

    mywindow.print()
    mywindow.close()
  }, 1000)


  return true
}

export function getDifferenceMinutes(start, end) {
  start = new Date(start)
  end = new Date(end)

  const diffMs = (end - start) // milliseconds between start & end

  const diffHrs = Math.floor((diffMs % 86400000) / 3600000)// hours

  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)

  return diffMins > 0 ? diffMins : diffHrs * 60
}

export function myFormattedDate(date) {
  const myDate = new Date(date)
  return `${myDate.getDate()}-${myDate.getMonth() + 1}-${myDate.getFullYear()} ${myDate.getHours()}:${myDate.getMinutes()}`
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeFirstLetterOnly(string) {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase()
}


export function checkRoute(path, array) {

  path = path.replace('/', '')
  const arr = path.split('/')
  const filter = arr.filter(val => val.includes(':'))
  for (let index = 0; index < filter.length; index++) {
    path = path.replace(`/${filter[index]}`, '')

  }
  path = path.replaceAll('//', '/')
  return array.findIndex(val => val === path) !== -1

}

function getUserData() {
  let userInfoFromStorage = null
  try {
    // userInfoFromStorage = JSON.parse(Cryptr.decrypt(localStorage.getItem('userData')))
    userInfoFromStorage = JSON.parse(localStorage.getItem('userData'))
  } catch (error) {
    userInfoFromStorage = null
  }
  return userInfoFromStorage ? userInfoFromStorage : null
}

function getUserToken() {
  let tokenFromStorage = null
  try {
    // userInfoFromStorage = JSON.parse(Cryptr.decrypt(localStorage.getItem('userData')))
    tokenFromStorage = localStorage.getItem('token')
  } catch (error) {
    tokenFromStorage = null
  }
  return tokenFromStorage ? tokenFromStorage : null
}

export function groupBy(array, prop) {
  const arr = groupByLoadash(array, prop)
  return arr
}


export function getColumnName(row) {
  const user = getUserData()
  if (!user) return
  if (row.Doctor) {
    if (user.user.id === row.Doctor.userId) {
      return "Treating Doctor"
    }
  }
  return "Speciality"
}

export function getColumnValue(row) {
  const user = getUserData()
  if (!user) return
  if (row.Doctor) {
    if (user.user.id === row.Doctor.userId) {
      return <b className='text-secondary'>{`Dr.${capitalizeFirstLetter(row.Doctor.firstName)} ${capitalizeFirstLetter(row.Doctor.lastName)}`}</b>
    }
    return row.Doctor.specialty ? row.Doctor.specialty.name : ""
  }
  return ""
}

export function splitWithChar(string, length = 15) {
  if (!string) {
    return ''
  }
  // const str_arr = string.split(char)
  if (string.length > length) return `${string.slice(0, length)} ...`

  return string
}


// export const BaseURL = "https://medcloudeg.com"
export const BaseURL = process.env.REACT_APP_BaseURL
// export const BaseURL = "https://mvb1.herokuapp.com"

export const FilesBaseURL = process.env.REACT_APP_FTPURL

export const QRKEY = process.env.REACT_APP_QRKEY

function CheckUserExists(userData) {
  if (!userData) {
    return false
  }
  if (!userData.user) {
    return false
  }
  return true
}

export class Roles {
  static DoctorRole() {
    return 8
  }
  static DoctorFdRole() {
    return 2
  }
  static LabFDRole() {
    return 3
  }
  static RadioFDRole() {
    return 4
  }
  static PathoFDRole() {
    return 5
  }
  static PharmacistRole() {
    return 17
  }
  static SubAdminRole() {
    return 19
  }
  static DoctorAdminRole() {
    return 20
  }
  static MainAdminRole() {
    return 21
  }
  static NurseRole() {
    return 7
  }
  static MedicalRegisteral() {
    return 24
  }
  static VendorRole() {
    return 25
  }
  static PHCURole() {
    return 26
  }
  static AreaAdminRole() {
    return 27
  }
  static PhysiotherapistRole() {
    return 28
  }
  static DentistRole() {
    return 29
  }
}

export class User {
  static userData = getUserData()
  static token = getUserToken()
  static timerShow = false
  static UpdateUserToken(token) {
    const tempData = this.userData
    this.userData.user.token = token
    localStorage.setItem('userData', JSON.stringify(tempData))
  }

  static getRole() {
    if (!CheckUserExists(this.userData)) return 0
    return parseInt(this.userData.user.roleId)
  }

  static isDoctor() {
    if (!CheckUserExists(this.userData)) return false
    return parseInt(this.userData.user.roleId) === 8
  }

  static isDoctorFD() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 2
  }

  static isLabFd() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 3
  }

  static isPathoFd() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 5
  }

  static isRadioFd() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 4
  }

  static isPharmicist() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 17
  }

  static isSubAdmin() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 19
  }

  static isDoctorAdmin() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 20
  }

  static isMainAdmin() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 21
  }

  static isNurse() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 7
  }

  static isMedicalRegisteral() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 24
  }

  static isVendor() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 25
  }

  
  static isPHCU() {
    if (!CheckUserExists(this.userData)) return false

    return parseInt(this.userData.user.roleId) === 26
  }

  static isAreaAdmin() {
    if (!CheckUserExists(this.userData)) return false
    return parseInt(this.userData.user.roleId) === 27
  }

  static canGo(path) {
    // return (doctorRoutes.includes(path) && this.isDoctor()) ||
    // (pathoFdRoutes.includes(path) && this.isPathoFd()) ||
    // (labFdRoutes.includes(path) && this.isLabFd()) ||
    // (radioFdRoutes.includes(path) && this.isRadioFd()) ||
    // (pharmcistRoutes.includes(path) && this.isPharmicist()) ||
    // (subAdminRoutes.includes(path) && this.isSubAdmin()) || 
    // (doctorFdRoutes.includes(path) && this.isDoctorFD()) || 
    // (doctorAdminRoutes.includes(path) && this.isDoctorAdmin()) ||
    // (mainAdminRoutes.includes(path) && this.isMainAdmin()) ||
    // (nurseRoutes.includes(path) && this.isNurse())
    return (
      (checkRoute(path, doctorRoutes) && this.isDoctor()) ||
      (checkRoute(path, pathoFdRoutes) && this.isPathoFd()) ||
      (checkRoute(path, labFdRoutes) && this.isLabFd()) ||
      (checkRoute(path, radioFdRoutes) && this.isRadioFd()) ||
      (checkRoute(path, pharmcistRoutes) && this.isPharmicist()) ||
      (checkRoute(path, subAdminRoutes) && this.isSubAdmin()) ||
      (checkRoute(path, doctorFdRoutes) && this.isDoctorFD()) ||
      (checkRoute(path, doctorAdminRoutes) && this.isDoctorAdmin()) ||
      (checkRoute(path, mainAdminRoutes) && this.isMainAdmin()) ||
      (checkRoute(path, nurseRoutes) && this.isNurse()) ||
      (checkRoute(path, medicalRegisteralRoutes) && this.isMedicalRegisteral()) ||
      (checkRoute(path, vendorRoutes) && this.isVendor()) ||
      (checkRoute(path, PHCURoutes) && this.isPHCU()) ||
      (checkRoute(path, areaAdminRoutes) && this.isAreaAdmin())

    )
  }

}

export const isLoggedIn = () => {
  const localData = User.userData
  if (!localData) {
    return false
  }
  if (localData.user && localData.user.roleId && User.token) {
    return true
  }
  return false
}

export const getLink = (currentLink, params) => {
  const arr = currentLink.split('/')

  for (const key in params) {
    if (arr.includes(params[key])) currentLink = currentLink.replace(params[key], '')
  }
  const splitPath = currentLink.split('')
  if (splitPath[0] === '/') splitPath[0] = ''
  if (splitPath[splitPath.length - 1] === '/') splitPath[splitPath.length - 1] = ''
  return splitPath.join('').replaceAll('//', '/')
}