const initialState = {
  loading: false,
  confirmed_doctors: [],
  notConfirmed_doctors: [],
  pending_doctors: [],
  not_active_doctor:[],

  confirmed_doctors_clinic_owners: [],
  notConfirmed_doctors_clinic_owners: [],
  pending_doctors_clinic_owners: [],

  confirmed_clinic_owners: [],
  notConfirmed_clinic_owners: [],
  pending_clinic_owners: [],

  confirmed_labfd: [],
  notConfirmed_labfd: [],
  pending_labfd: [],
  not_active_labfd:[],


  confirmed_radiofd: [],
  pending_radiofd: [],
  notConfirmed_radiofd: [],
  not_active_radiofd:[],


  confirmed_pathlogyfd: [],
  pending_pathlogyfd: [],
  notConfirmed_pathlogyfd: [],
  not_active_labfd:[],


  confirmed_pharmacist: [],
  pending_pharmacist: [],
  notConfirmed_pharmacist: [],
  not_active_pharmacist:[],


  confirmed_dentist: [],
  pending_dentist: [],
  notConfirmed_dentist: [],
  not_active_dentist:[],


  confirmed_physiotherapist: [],
  pending_physiotherapist: [],
  notConfirmed_physiotherapist: [],
  not_active_physiotherapist:[],


  employee_prices:[],
  areaAdmins: [],

  services: [],
  err: null
}

const MainAdminReducer = (state = initialState, action) => {
  if (action.type.includes("MAINADMIN") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("MAINADMIN") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
      switch (action.type) {
          case "GET_MAINADMIN_DOCTORS_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_doctors: action.confirmedDocotrs,
                pending_doctors: action.pendingDocotrs,
                notConfirmed_doctors: action.notConfirmedDocotrs,
                not_active_doctor:action.notactiveDoctors
              }
              case "GET_MAINADMIN_CONFIRMED_DOCTORS_SUCCESS":
                return {
                  ...state,
                  loading: false,
                  confirmed_doctors: action.confirmedDocotrs
                }
              case "PRICE_CREATE_SUCCESS":
                  return {
                    ...state,
                    loading: false
                  }               
          case "GET_MAINADMIN_DOCTOR_CLINIC_OWNERS_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_doctors_clinic_owners: action.confirmedDocotrClinicOwners,
                pending_doctors_clinic_owners: action.pendingDocotrClinicOwners,
                notConfirmed_doctors_clinic_owners: action.notConfirmedDocotrClinicOwners
              }
          case "GET_MAINADMIN_CLINIC_OWNERS_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_clinic_owners: action.confirmedClinicOwners,
                pending_clinic_owners: action.pendingClinicOwners,
                notConfirmed_clinic_owners: action.notConfirmedClinicOwners
              }         
          case "GET_MAINADMIN_LABFD_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_labfd: action.confirmedLabFD,
                pending_labfd: action.pendingLabFD,
                not_active_labfd:action.notActiveLabFD,
                notConfirmed_labfd: action.notConfirmedLabFD
              }
          case "GET_MAINADMIN_RADIOFD_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_radiofd: action.confirmedRadiologist,
                pending_radiofd: action.pendingRadiologist,
                notConfirmed_radiofd: action.notConfirmedRadiologist,
                not_active_radiofd: action.notActiveRadioFD

              }
          case "GET_MAINADMIN_PATHOFD_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_pathlogyfd: action.confirmedPathologist,
                pending_pathlogyfd: action.pendingPathologist,
                notConfirmed_pathlogyfd: action.notConfirmedPathologist
              }
          case "GET_MAINADMIN_PAHARMACIST_SUCCESS":
              return {
                ...state,
                loading: false,
                confirmed_pharmacist: action.confirmedPharmacist,
                pending_pharmacist: action.pendingPharmacist,
                notConfirmed_pharmacist: action.notConfirmedPharmacist,
                not_active_pharmacist:action.notActivePharmacist
              }   
          case 'MAINADMIN_ALL_AREAADMINS_SUCCESS':
                return {
                  ...state,
                  areaAdmins: action.data,
                  loading: false
                }    
          case 'MAINADMIN_CREATE_AD_SUCCESS':
            return {...state, loading: false}
                
          case "GET_MAINADMIN_DENTIST_SUCCESS":
                return {
                  ...state,
                  loading: false,
                  confirmed_dentist: action.confirmedDentist,
                  pending_dentist: action.pendingDentist,
                  notConfirmed_dentist: action.notConfirmedDentist,
                  not_active_dentist:action.notActiveDentist

                } 
          case "GET_MAINADMIN_PHYSIOTHERAPIST_SUCCESS":
                  return {
                    ...state,
                    loading: false,
                    confirmed_physiotherapist: action.confirmedPhysiotherapist,
                    pending_physiotherapist: action.pendingPhysiotherapist,
                    notConfirmed_physiotherapist: action.notConfirmedPhysiotherapist,
                    not_active_physiotherapist: action.notActivePhysiotherapist
                  }                                         
          case "MAINADMIN_CONFIRM_DOCTOR_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_CONFIRM_LABFD_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_CONFIRM_RADIOFD_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_CONFIRM_PATHOFD_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_CONFIRM_PHARMACIST_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_PRICE_CREATE_SUCCESS":
            return {...state, loading: false}
          case "MAINADMIN_SERVICES_SUCCESS":
            return {...state, services: action.data, loading: false}
          case "MAINADMIN_CATEGORIES_SUCCESS":
            return {...state, categories: action.data, loading: false}  
          case "MAINADMIN_PRICE_EMPLOYEE_SUCCESS":
            return {...state, employee_prices: action.data, loading: false}  
          default:
              return {...state}
      }
  }
}

export default MainAdminReducer
